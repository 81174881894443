body {
  overflow: hidden;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
html,
body,
#root {
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#superset-container,
iframe {
  width: 100%;
  height: 100%;
  border: 0;
  flex: 1;
}
.landscape {
  transform: rotate(90deg);
  transform-origin: left top;
  width: 101vh !important;
  height: 100vw !important;
  position: fixed;
  top: 0;
  left: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.errorContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.errorContainer h3 {
  color: red;
  margin-top: 40px;
  text-align: center;
}

.utility {
  position: fixed;
  bottom: 10vw;
  right: 5vw;
  z-index: 10000000;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.utility-container {
  position: relative;
}
#options {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  background-color: #fff;
  margin-bottom: 12vw;
}
#options h1 {
  font-size: 4.5vw;
  text-align: right;
  color: #000;
  font-weight: 400;
}
#options.open {
  max-height: 500px;
}

.divider {
  border-top: 1px solid #aaa;
}
.option {
  padding: 1vw;
}

.icon {
  font-size: 20px;
  object-fit: contain;
  width: 10vw;
  height: 10vw;
  padding: 2vw;
  border-radius: 1vw;
  background-color: rgb(51, 51, 51);
}
.menu-icon {
  width: 10vw;
  height: 10vw;
  object-fit: contain;
  transition: opacity 0.6s linear;
  position: absolute;
  bottom: 2vw;
  margin-left: 1vw;
}
.btn {
  width: 10vw;
  height: 10vw;
  border-right: 0;
  border-width: 1px;
  background-color: rgb(51, 51, 51);
  color: white;
  font-size: 5vw;
  border: 0;
  border-radius: 10px;
}

.menu .menu-icon:nth-child(1) {
  visibility: hidden;
  opacity: 0;
}

#options.open + .menu .menu-icon:nth-child(1) {
  visibility: visible;
  opacity: 1;
}

#options.open + .menu .menu-icon:nth-child(2) {
  visibility: hidden;
  opacity: 0;
}
